import { Popconfirm, Popover, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { ReactNode } from "react";
import isEqual from "react-fast-compare";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { selectActiveReportUsers } from "../../../../store/selectors/usersSelectors";
import { BLUE_COLOR, ContractTypeMode, GREEN_COLOR, MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_MONTH_FORMAT, RED_COLOR } from "../../../../utils/constants";
import Network from "../../../../utils/network";
import { FullUserProps, withFullName } from "../../../../utils/objects/withFullName";
import { userColumn } from "../../../../utils/tableUtils";
import { DictionaryString, IFilterUsers, ISignatureStatus, IUserContractInfo, SignatureStatus, User, UserJobTMP } from "../../../../utils/types/generalTypes";
import { CcntReport, CcntReports } from "../../../../utils/types/reportTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { filterUsers, isNullOrEmpty, showNotification } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";
import FAIcon from "../../../common/FAIcon";
import ActionsToolbar from "../../../common/fields/ActionsToolbar/actionsToolbar";
import AmazingDatePicker, { PickerMode, PickerModeType } from "../../../common/fields/AmazingDatePicker/amazingDatePicker";
import CircleButton from "../../../common/fields/circleButton";
import VerticalDivider from "../../../common/general/verticalDivider";
import VirtualTable from "../../../common/general/virtualTable";
import { FilterSidebar } from "../../../common/navigations/containerTabs";
import { ContainerTabsItem, ContainerTabsItemProps } from "../../../common/navigations/containerTabsItem";
import Filters from "../../../planningPerf/tabs/common/filters";
import SignatureReminderModal from "../common/signatureReminderModal/signatureReminderModal";
import CCNTSignatureCell from "./CCNTSignatureCell";
import DrawerCcntReport from "./drawerCcntReport";
import MultipleCcntReportPrintPage from "./multipleCcntReportPrintPage";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps, ContainerTabsItemProps, FullUserProps { }
type UserWithContract = User & { c: UserJobTMP; fullId: string; };

interface State {
    startDate: Moment;
    endDate: Moment;
    selectedUsers: string[];
    ccntBalances: DictionaryString<CcntBalancesByUserId>;
    signatures: DictionaryString<ISignatureStatus>;
    multipleCcnt?: CcntReports;
    isMultipleCcntVisible: boolean;
    loading: boolean;
    loadingCcnt: boolean;
    ccnt: CcntReport | undefined;
    filters: IFilterUsers & { signatureStatuses: SignatureStatus[]; };
    datePickerType?: PickerModeType;
    signatureRequestLoading: boolean;
    reminderModalOpened: boolean;
    isSendingReminder: boolean;
}

class BalanceHours extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);
        const startDate = moment().startOf('year');
        const endDate = moment().endOf(PickerMode.MONTH);

        this.state = {
            startDate,
            endDate,
            selectedUsers: [],
            ccntBalances: {},
            signatures: {},
            loading: false,
            loadingCcnt: false,
            ccnt: undefined,
            isMultipleCcntVisible: false,
            datePickerType: PickerMode.MONTH,
            signatureRequestLoading: false,
            filters: {
                users: [],
                groups: [],
                usersToExclude: [],
                signatureStatuses: [],
            },
            reminderModalOpened: false,
            isSendingReminder: false
        };
    }

    componentDidMount = () => {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.refreshData();
    };

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
        const { endDate, datePickerType } = this.state;

        if (
            prevState.loading !== this.state.loading ||
            prevState.loadingCcnt !== this.state.loadingCcnt ||
            !prevState.endDate.isSame(this.state.endDate, datePickerType ?? 'day')
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }

        if (!endDate.isSame(prevState.endDate, "date")) {
            this.refreshData();
        }
    };

    getExtra = () => {
        const { intl } = this.props;
        const { loading, loadingCcnt, endDate } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.MONTH}
                    disabled={loadingCcnt}
                    loadingData={loading}
                    selectByDay
                    selectByMonth
                    controlled={{
                        valueFrom: endDate,
                        onChange: this.onChangeDate,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={this.refreshData}
                    disabled={loadingCcnt}
                    loading={loading} />
                <VerticalDivider />
                <Popover
                    placement='left'
                    trigger={"click"}
                    title={<FormattedMessage defaultMessage={'N-CLA interactive message checklist'} />}
                    content={
                        <div style={{ maxWidth: '800px' }}>
                            <h3 style={{ marginBottom: '5px' }}><FormattedMessage defaultMessage={'Informations'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'More than 14 hours of work in a single day.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Warnings'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'Week with more than 50 working hours.'} /></li>
                                <li><FormattedMessage defaultMessage={'Week without 2 days off.'} /></li>
                                <li><FormattedMessage defaultMessage={'More than 24 hours of work in a single day. Check plausibility.'} /></li>
                                <li><FormattedMessage defaultMessage={'Negative working hours.'} /></li>
                                <li><FormattedMessage defaultMessage={'More than 6 working days without rest days.'} /></li>
                                <li><FormattedMessage defaultMessage={'Incompatible types of day according to N-CLA standards. If several event types are present on the same day, check that they are of type X, F, M or T.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Warnings visible in the printed version'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'More than 5 hours of work on a half-day off. The half-day of leave/holiday/sick leave will not be counted.'} /></li>
                            </ul>

                            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}><FormattedMessage defaultMessage={'Errors'} /></h3>
                            <ul>
                                <li><FormattedMessage defaultMessage={'Invalid type of day. Correct event type. Valid types: T | X | F | V | Mi | M | A | Ma | D.'} /></li>
                                <li><FormattedMessage defaultMessage={'Too many different events on the same day. Check that there are no more than two types of day. Check that there are no more than 2 events if they are not of type X, F or T.'} /></li>
                            </ul>
                        </div>
                    }
                >
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='question' />}
                    />
                </Popover>
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
                signatureStatus={{
                    signatureStatuses: filters.signatureStatuses,
                    change: (s) => this.setState(prevState => ({ filters: { ...prevState.filters, signatureStatuses: s } }))
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    resetFilters = () => this.setState({ filters: { users: [], groups: [], usersToExclude: [], signatureStatuses: [] } });

    isSignatureEnabled = (dt?: Moment) => {
        dt = dt ?? this.state.endDate;
        return this.props.company?.isSignedReportModuleEnabled && dt.year() >= 2025;
    };

    showSignatureColumn = () => {
        const isPreviousMonth = this.state.endDate.isBefore(moment(), 'month');
        const isEndOfMonth = this.state.endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK) === this.state.endDate.clone().endOf('month').format(MOMENT_FORMAT_DATE_TO_NETWORK);
        return /*this.isSignatureEnabled() && */ isEndOfMonth && isPreviousMonth && this.state.endDate.year() >= 2025;
    };

    onChangeDate = (from: Moment | null, to: Moment | null, type?: PickerModeType) => {
        if (!from || !to) return;
        const start = from.clone().startOf('year');
        const end = type ? to.clone().endOf(type) : to.clone();

        this.setState({
            startDate: start,
            endDate: end,
            datePickerType: type,
            filters: {
                ...this.state.filters,
                signatureStatuses: this.isSignatureEnabled(end) ? this.state.filters.signatureStatuses : []
            }
        });
    };

    requestSignature = (usersData: IUserContractInfo[]) => {
        this.setState({ signatureRequestLoading: true });
        const { endDate } = this.state;
        Network.requestSignature(usersData, endDate).then(
            (response) => {
                const successIds = response.data.successUserIds;
                const errorIds = response.data.errorUserIds;
                if (successIds.length) {
                    showNotification(`${successIds.length} demandes de signature envoyées`, "success");
                    const tmpSignatures = cloneDeep(this.state.signatures);
                    for (const id of successIds)
                        if (id in tmpSignatures)
                            tmpSignatures[id].status = SignatureStatus.AWAITING;
                    this.setState({ signatures: tmpSignatures, signatureRequestLoading: false });
                }
                if (errorIds.length) {
                    this.setState({ signatureRequestLoading: false });
                    showNotification(`${errorIds.length} demandes de signature ont rencontré une erreur`, "error");
                }
            }
        );
    };

    remindSignature = (reportIds: number[]) => {
        this.setState({ isSendingReminder: true });
        Network.remindSignature(reportIds).then(
            (response) => {
                const successIds = response.successIds;
                const errorIds = response.errorIds;
                console.log(successIds, errorIds);
                if (successIds?.length) {
                    showNotification(`${successIds.length} rappels envoyées`, "success");
                    const tmpSignatures = cloneDeep(this.state.signatures);
                    for (const id of successIds)
                        if (id in tmpSignatures) {
                            tmpSignatures[id].reminderSent = moment().format("YYYY-MM-DDTHH:mm:ss");
                        }
                    this.setState({ signatures: tmpSignatures });
                }
                if (errorIds?.length) {
                    showNotification(`${errorIds.length} rappels ont rencontré une erreur`, "error");
                }

                this.setState({ isSendingReminder: false });
            }
        );
    };

    cancelReports = (usersData: IUserContractInfo[]) => {
        Network.cancelReport(usersData, this.state.endDate).then(
            () => {
                const tmpSignatures = cloneDeep(this.state.signatures);
                for (const userData of usersData) {
                    tmpSignatures[`${userData.userId}-${userData.contractId}`] = {
                        month: null,
                        reminderSent: null,
                        reportId: null,
                        signedDate: null,
                        status: SignatureStatus.SIGNABLE,
                        svg: null
                    };
                    this.setState({ signatures: tmpSignatures });
                }
            }
        );
    };

    getCcntReport = (userData?: IUserContractInfo, onlySelected = false) => {
        const { startDate, endDate, selectedUsers, loadingCcnt } = this.state;
        if ((!userData && isNullOrEmpty(selectedUsers)) || loadingCcnt) return;
        this.setState({ loadingCcnt: true });

        if (userData) {
            Network.generateReportsCcntSigned(startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), [userData]).then(
                (networkCCnt) => {
                    const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                    this.setState({ loadingCcnt: false, ccnt });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generatin the report n°{user}' }, { user: userData.userId }), "warning");
                    this.setState({ loadingCcnt: false });
                }
            );
        } else {
            const usersData = this.getUserWithContractsForRequests(onlySelected);
            Network.generateReportsCcntSigned(startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), usersData).then(
                (networkCCnt) => {
                    const ccntReports: CcntReports = networkCCnt;
                    this.setState({ loadingCcnt: false, selectedUsers: [], multipleCcnt: ccntReports, isMultipleCcntVisible: true });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                    this.setState({ loadingCcnt: false, isMultipleCcntVisible: true });
                }
            );
        }
    };

    renderTag = (value: number, inverted = false) => {
        const color = Math.round(value * 100) / 100 === 0 ? BLUE_COLOR : value < 0 ? (inverted ? GREEN_COLOR : RED_COLOR) : (inverted ? RED_COLOR : GREEN_COLOR);

        // By @Axel @Mario ntm
        return <span style={{ background: color + "33", padding: '5px 10px', color: color, borderRadius: 999 }}>{value}</span>;
    };

    getUserWithContractsForRequests = (onlySelected = false) => {
        const { endDate } = this.state;
        const endDateNb = parseInt(endDate.format("YYYYMM"));
        return this.props.users.reduce((arr, user) => {
            if (user.job) {
                for (const job of user.job) {
                    if (job.id) {
                        if (!onlySelected || this.state.selectedUsers.includes(`${user.id}-${job.id}`)) {
                            const start = parseInt(moment(job.date_in_report).format("YYYYMM"));
                            const end = parseInt(moment(job.contract_expiry_date ?? '2200-01-01').format("YYYYMM"));
                            console.log(user.first_name, user.last_name, endDateNb, start, end, endDateNb >= start && endDateNb <= end);
                            if (endDateNb >= start && endDateNb <= end) {
                                arr.push({ userId: user.id, contractId: job.id });
                            }
                        }
                    }
                }
            }

            return arr;
        }, [] as IUserContractInfo[]);
    };

    refreshData = () => {
        this.setState({
            selectedUsers: [],
        });
        this.getAllCcntData();
        this.getAllSignatureStatuses();
    };

    getAllSignatureStatuses = () => {
        if (this.isSignatureEnabled() && this.showSignatureColumn()) {
            const { endDate } = this.state;
            const usersData = this.getUserWithContractsForRequests();
            if (this.props.company?.isSignedReportModuleEnabled) {
                Network.getSignatureStatus(usersData, endDate).then(
                    (response) => {
                        this.setState({ signatures: response.data });
                    }
                );
            }
        } else {
            if (Object.keys(this.state.signatures).length > 0) {
                this.setState({ signatures: {} });
            }
        }
    };

    getAllCcntData = () => {
        const { startDate, endDate } = this.state;

        const usersData = this.getUserWithContractsForRequests();

        this.setState({ loading: true });
        Network.generateReportsCcntV2Balances(startDate.format(MOMENT_MONTH_FORMAT), endDate.format(MOMENT_MONTH_FORMAT), usersData).then(
            (response) => {
                const ccntBalances = Object.fromEntries(
                    usersData.map(({ userId, contractId }) => {
                        const key = `${userId}-${contractId}`;
                        if (key in response) {
                            const userData = response[key];
                            console.log(key, userData);
                            const monthValues = userData && userData.months[endDate.month()] ? userData.months[endDate.month()] : undefined;

                            const balances = userData ? userData.tally : {
                                finalWorkTime: 0,
                                finalRestDelta: 0,
                                finalVacationDelta: 0,
                                finalHolidayDelta: 0
                            };

                            const effectives = {
                                hours: monthValues ? monthValues.tally.T : 0,
                                rests: monthValues ? monthValues.tally.X : 0,
                                vacations: monthValues ? monthValues.tally.V : 0,
                                holidays: monthValues ? monthValues.tally.F : 0,
                            };

                            const daysOff = {
                                sickness: monthValues ? monthValues.tally.M + monthValues.tally.Ma : 0,
                                accident: monthValues ? monthValues.tally.A : 0,
                                military: monthValues ? monthValues.tally.Mi : 0,
                                others: monthValues ? monthValues.tally.D : 0,
                            };

                            return [
                                key,
                                {
                                    balances: {
                                        hours: balances.finalWorkTimeDelta.toFixed(2),
                                        rests: balances.finalRestDelta.toFixed(2),
                                        vacations: balances.finalVacationDelta.toFixed(2),
                                        holidays: balances.finalHolidayDelta.toFixed(2)
                                    },
                                    effectives: {
                                        hours: effectives.hours.toFixed(2),
                                        rests: effectives.rests.toFixed(2),
                                        vacations: effectives.vacations.toFixed(2),
                                        holidays: effectives.holidays.toFixed(2)
                                    },
                                    daysOff: {
                                        sickness: daysOff.sickness.toFixed(2),
                                        accident: daysOff.accident.toFixed(2),
                                        military: daysOff.military.toFixed(2),
                                        others: daysOff.others.toFixed(2),
                                    },
                                    contractTypeMode: userData.data.contractTypeMode,
                                }
                            ];
                        } else {
                            return [
                                key,
                                {
                                    balances: {
                                        hours: "-",
                                        rests: "-",
                                        vacations: "-",
                                        holidays: "-"
                                    },
                                    effectives: {
                                        hours: "-",
                                        rests: "-",
                                        vacations: "-",
                                        holidays: "-",
                                    },
                                    daysOff: {
                                        sickness: "-",
                                        accident: "-",
                                        military: "-",
                                        others: "-",
                                    },
                                    contractTypeMode: ContractTypeMode.NORMAL,
                                }
                            ];
                        }
                    })
                );
                this.setState({ loading: false, ccntBalances });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ loading: false });
            }
        );
    };

    isFixed = (contractTypeMode: number) => [ContractTypeMode.NORMAL].includes(contractTypeMode);

    handleOnRow = (record: UserWithContract) => {
        if (record.c.id !== undefined)
            this.getCcntReport({ userId: record.id, contractId: record.c.id });
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as string[] });

    render = () => {
        const { groups, users, height, intl, isSmartphone, getFullName } = this.props;
        const { signatures, endDate, loading, loadingCcnt, ccnt, ccntBalances, filters, isMultipleCcntVisible, multipleCcnt, selectedUsers } = this.state;
        const endDateNb = parseInt(endDate.format("YYYYMM"));
        let tableHeight = height - 172 - (this.isSignatureEnabled() && this.showSignatureColumn() ? 70 : 0);
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);
        const year = endDate.year();

        let filteredUsersWithContract = filteredUsers?.reduce((uWithContracts, user) => {
            if (user.job) {
                for (const job of user.job) {
                    if (job.id) {
                        const start = parseInt(moment(job.date_in_report).format("YYYYMM"));
                        const end = parseInt(moment(job.contract_expiry_date ?? '2200-01-01').format("YYYYMM"));
                        if (endDateNb >= start && endDateNb <= end) {
                            uWithContracts.push({ ...user, c: job, fullId: `${user.id}-${job.id}` });
                        }
                    }
                }
            }
            return uWithContracts;
        }, [] as UserWithContract[]) ?? [];

        if (this.state.filters.signatureStatuses.length)
            filteredUsersWithContract = filteredUsersWithContract?.filter((u) => {
                if (u.fullId in signatures)
                    return this.state.filters.signatureStatuses.includes(signatures[u.fullId].status);

                return false;
            });


        const columns: ColumnsType<UserWithContract> = [
            ...(userColumn(intl, getFullName, isSmartphone) as ColumnsType<UserWithContract>),
            {
                title: "Contract",
                className: '__min-width-140',
                render: (_, record) => (
                    <div className="ccnt-contract-column">
                        <div>{record.c.name}</div>
                        {/*<div className="contract-date">
                            <div>{moment(record.c.date_in_report).format("D MMM YYYY")}</div>
                            {record.c.contract_expiry_date
                                ?
                                <>
                                    <div>-</div>
                                    <div>{moment(record.c.contract_expiry_date).format("D MMM YYYY")}</div>
                                </>
                                :
                                ''
                            }
                        </div> */}
                    </div>
                )
            },
            {
                title: <FormattedMessage defaultMessage={'Effective time'} />,
                key: 'effective',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'effective-hours',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[b.fullId].effectives.hours - ccntBalances[a.fullId].effectives.hours),
                        render: (_, record) => (
                            ccntBalances[record.fullId] && this.isFixed(ccntBalances[record.fullId].contractTypeMode) ?
                                ccntBalances[record.fullId].effectives.hours ?
                                    ccntBalances[record.fullId].effectives.hours
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'effective-rests',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].effectives.rests - ccntBalances[b.fullId].effectives.rests),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].effectives.rests ?
                                    ccntBalances[record.fullId].effectives.rests
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'effective-vacations',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].effectives.vacations - ccntBalances[b.fullId].effectives.vacations),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].effectives.vacations ?
                                    ccntBalances[record.fullId].effectives.vacations
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'effective-holidays',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].effectives.holidays - ccntBalances[b.fullId].effectives.holidays),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].effectives.holidays ?
                                    ccntBalances[record.fullId].effectives.holidays
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Days off'} />,
                key: 'daysoff',
                className: '__width_480',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Sickness'} />,
                        key: 'daysoff-sickness-maternity',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[b.fullId].daysOff.sickness - ccntBalances[a.fullId].daysOff.sickness),
                        render: (_, record) => (
                            ccntBalances[record.fullId] && this.isFixed(ccntBalances[record.fullId].contractTypeMode) ?
                                ccntBalances[record.fullId].daysOff.sickness ?
                                    ccntBalances[record.fullId].daysOff.sickness
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Accident'} />,
                        key: 'daysoff-accident',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].daysOff.accident - ccntBalances[b.fullId].daysOff.accident),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].daysOff.accident ?
                                    ccntBalances[record.fullId].daysOff.accident
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Military'} />,
                        key: 'daysoff-military',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].daysOff.military - ccntBalances[b.fullId].daysOff.military),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].daysOff.military ?
                                    ccntBalances[record.fullId].daysOff.military
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Others'} />,
                        key: 'daysoff-others',
                        className: '__width_120',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].daysOff.others - ccntBalances[b.fullId].daysOff.others),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].daysOff.others ?
                                    ccntBalances[record.fullId].daysOff.others
                                    : 0
                                : '-'
                        )
                    }
                ]
            },
            {
                title: <FormattedMessage defaultMessage={'Balances'} />,
                key: 'balance',
                fixed: 'right',
                className: '__width_480 __report-hours-summary-fixed-odd',
                children: [
                    {
                        title: <FormattedMessage defaultMessage={'Hours'} />,
                        key: 'balance-hours',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[b.fullId].balances.hours - ccntBalances[a.fullId].balances.hours),
                        render: (_, record) => (
                            ccntBalances[record.fullId] && this.isFixed(ccntBalances[record.fullId].contractTypeMode) ?
                                ccntBalances[record.fullId].balances.hours ?
                                    this.renderTag(ccntBalances[record.fullId].balances.hours, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Rest'} />,
                        key: 'balance-rests',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].balances.rests - ccntBalances[b.fullId].balances.rests),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].balances.rests ?
                                    this.renderTag(ccntBalances[record.fullId].balances.rests, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Vacations'} />,
                        key: 'balance-vacations',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].balances.vacations - ccntBalances[b.fullId].balances.vacations),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].balances.vacations ?
                                    this.renderTag(ccntBalances[record.fullId].balances.vacations, true)
                                    : 0
                                : '-'
                        )
                    },
                    {
                        title: <FormattedMessage defaultMessage={'Holidays'} />,
                        key: 'balance-holidays',
                        fixed: 'right',
                        className: '__width_120 __report-hours-summary-fixed-odd',
                        align: 'center',
                        sorter: (a, b) => (!ccntBalances[a.fullId] ? -1 : !ccntBalances[b.fullId] ? 1 : ccntBalances[a.fullId].balances.holidays - ccntBalances[b.fullId].balances.holidays),
                        render: (_, record) => (
                            ccntBalances[record.fullId] ?
                                ccntBalances[record.fullId].balances.holidays ?
                                    this.renderTag(ccntBalances[record.fullId].balances.holidays, true)
                                    : 0
                                : '-'
                        )
                    },
                ]
            },
            ...(this.showSignatureColumn() ? [{
                title: (<Popover content={<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><FAIcon prefix='fad' name='clock-twelve' fontSize={20} color={BLUE_COLOR} className="spinning" /><p><FormattedMessage defaultMessage={'Awaiting signature'} /></p></div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><FAIcon prefix='fad' name='circle-exclamation' fontSize={20} color={'#FF7F50'} /><p><FormattedMessage defaultMessage={'Previous month not signed yet'} /></p></div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><FAIcon prefix='fad' name='do-not-enter' fontSize={20} color={"#ff7f7f"} /><p><FormattedMessage defaultMessage={'Previous signature not requested'} /></p></div>
                </div>}>
                    <FAIcon prefix="fad" name="signature" fontSize={25} />
                </Popover>),
                key: "sign",
                fixed: 'right',
                className: '__width_80 ccnt-signature-cell-td',
                align: 'center',
                onCell: () => ({ onClick: (e) => e.stopPropagation() }),
                render: this.isSignatureEnabled()
                    ?
                    (_, record) => {
                        const s = signatures[record.fullId];
                        if (s && record.c.id) {
                            const contractId = record.c.id as number;
                            return <CCNTSignatureCell
                                year={year}
                                contractName={record.c.name}
                                contractEndDate={record.c.contract_expiry_date ? moment(record.c.contract_expiry_date) : moment().year(2200)}
                                signature={s}
                                requestSignature={() => this.requestSignature([{ userId: record.id, contractId }])}
                                remindSignature={() => s.reportId && this.remindSignature([s.reportId])}
                                cancelReport={() => this.cancelReports([{ contractId, userId: record.id }])}
                            />;
                        }
                        else return <></>;
                    }
                    :
                    () => {
                        return <CCNTSignatureCell
                            year={year}
                            signature={{ status: SignatureStatus.SIGNABLE, svg: null, month: null, reportId: null, signedDate: null, reminderSent: null }}
                            requestSignature={() => showNotification(intl.formatMessage({ defaultMessage: "Please contact WebEvolutions if you wish to activate the signature module." }), "info")}
                            remindSignature={console.log}
                        />;
                    }
            }] as ColumnsType<UserWithContract> : []),
        ];

        const signableUsers = filteredUsersWithContract?.reduce((arr, user) => {
            const signature = signatures[user.fullId];
            if (user.c.id && signature && this.state.selectedUsers.includes(user.fullId)) {
                if (signature.status === SignatureStatus.SIGNABLE) {
                    arr.push({
                        contractId: user.c.id,
                        userId: user.id
                    });
                }
            }
            return arr;
        }, [] as IUserContractInfo[]) ?? [];

        const remindableUsers = filteredUsersWithContract?.reduce((arr, user) => {
            const signature = signatures[user.fullId];
            if (user.c.id && signature && signature.reportId && this.state.selectedUsers.includes(user.fullId)) {
                if ([SignatureStatus.AWAITING, SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE].includes(signature.status)) {
                    arr.push({
                        contractId: user.c.id,
                        userId: user.id,
                        signature
                    });
                }
            }
            return arr;
        }, [] as (IUserContractInfo & { signature: ISignatureStatus; })[]) ?? [];

        const signedReportCounts = Object.keys(signatures).reduce((counters, key) => {
            const signature = signatures[key as unknown as number];
            counters[signature.status] = counters[signature.status] !== undefined ? counters[signature.status] + 1 : 1;
            counters['TOTAL']++;
            return counters;
        }, {
            'TOTAL': 0,
            [SignatureStatus.AWAITING]: 0,
            [SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE]: 0,
            [SignatureStatus.SIGNED]: 0,
            [SignatureStatus.PREVIOUS_MONTH_NOT_REQUESTED]: 0,
            [SignatureStatus.SIGNABLE]: 0,
        } as Record<SignatureStatus | 'TOTAL', number>);

        const divider = <div style={{ height: '50%', width: '1px', background: 'lightgrey' }} />;

        return (
            <>
                <Spin spinning={loading} indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                    {
                        this.isSignatureEnabled() && this.showSignatureColumn()
                            ?
                            <div style={{ borderRadius: '12px', display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px', border: '.5px solid white', height: '50px', justifyContent: 'space-evenly' }}>
                                <StatCard
                                    onClick={() => this.setState({ filters: { ...this.state.filters, signatureStatuses: [SignatureStatus.SIGNED] } })}
                                    count={signedReportCounts[SignatureStatus.SIGNED]}
                                    text={intl.formatMessage({ defaultMessage: "signed reports", description: "Shown next to a number, no capital letters" })}
                                    icon={<FAIcon prefix="fad" name="circle-check" fontSize={17} />}
                                    color={GREEN_COLOR}
                                />
                                {divider}
                                <StatCard
                                    onClick={() => this.setState({ filters: { ...this.state.filters, signatureStatuses: [SignatureStatus.SIGNABLE] } })}
                                    count={signedReportCounts[SignatureStatus.SIGNABLE]}
                                    text={intl.formatMessage({ defaultMessage: "signable reports", description: "Shown next to a number, no capital letters" })}
                                    icon={<FAIcon prefix="fad" name="signature" color={'dark'} fontSize={17} />}
                                    color={'dark'}
                                />
                                {divider}
                                <StatCard
                                    onClick={() => this.setState({ filters: { ...this.state.filters, signatureStatuses: [SignatureStatus.AWAITING] } })}
                                    count={signedReportCounts[SignatureStatus.AWAITING]}
                                    text={intl.formatMessage({ defaultMessage: "awaiting signature", description: "Shown next to a number, no capital letters" })}
                                    icon={<FAIcon prefix="fad" name="clock-twelve" fontSize={17} className="spinning" />}
                                    color={BLUE_COLOR}
                                />
                                {signedReportCounts[SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE] ? <>
                                    {divider}
                                    <StatCard
                                        onClick={() => this.setState({ filters: { ...this.state.filters, signatureStatuses: [SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE] } })}
                                        count={signedReportCounts[SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE]}
                                        text={intl.formatMessage({ defaultMessage: "previous month awaiting signature", description: "Shown next to a number, no capital letters" })}
                                        icon={<FAIcon prefix="fad" name="circle-exclamation" fontSize={17} />}
                                        color={"#FF7F50"}
                                    />
                                </> : null}
                                {signedReportCounts[SignatureStatus.PREVIOUS_MONTH_NOT_REQUESTED] ? <>
                                    {divider}
                                    <StatCard
                                        onClick={() => this.setState({ filters: { ...this.state.filters, signatureStatuses: [SignatureStatus.PREVIOUS_MONTH_NOT_REQUESTED] } })}
                                        count={signedReportCounts[SignatureStatus.PREVIOUS_MONTH_NOT_REQUESTED]}
                                        text={intl.formatMessage({ defaultMessage: "previous month not requested", description: "Shown next to a number, no capital letters" })}
                                        icon={<FAIcon prefix="fad" name="do-not-enter" fontSize={17} />}
                                        color={"#ff7f7f"}
                                    />
                                </> : null
                                }
                            </div>
                            :
                            null
                    }

                    <div style={{ display: 'flex', gap: 10 }}>
                        {filteredUsersWithContract.length
                            ?
                            <>
                                <VirtualTable
                                    className='__basic-table'
                                    onRow={(e: UserWithContract) => ({
                                        onClick: () => this.handleOnRow(e)
                                    })}
                                    rowClassName={(record: UserWithContract) => record.c.id === undefined ? 'disabled-row' : ''}
                                    rowKey={(u: UserWithContract) => `${u.id}-${u.c.id ?? '0'}`}
                                    rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                                    dataSource={filteredUsersWithContract}
                                    columns={columns}
                                    // style={{ flex: 1, overflow: 'auto' }}
                                    pagination={false}
                                    scroll={{ x: true, y: tableHeight }}
                                />
                                <ActionsToolbar
                                    open={selectedUsers.length > 0}
                                    close={() => this.setState({ selectedUsers: [] })}
                                    title={`${selectedUsers.length.toString()} ${intl.formatMessage({ defaultMessage: "Users" })}`}
                                    actions={
                                        <>
                                            {remindableUsers.length ?
                                                <CircleButton
                                                    small
                                                    disabled={this.state.signatureRequestLoading}
                                                    icon={< FAIcon prefix='fad' name='bell' />}
                                                    onClick={() => this.setState({ reminderModalOpened: true })}
                                                    loading={this.state.loadingCcnt}
                                                />
                                                : null}
                                            {signableUsers.length ? <Popconfirm
                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Ask to sign the report} other {Ask to sign {count} reports}}' }, { count: signableUsers.length })}
                                                okText={<FormattedMessage defaultMessage={"Yes"} />}
                                                cancelText={<FormattedMessage defaultMessage={"No"} />}
                                                onConfirm={() => this.requestSignature(signableUsers)}
                                            >
                                                <CircleButton
                                                    small
                                                    disabled={this.state.signatureRequestLoading}
                                                    icon={< FAIcon prefix='fad' name='signature' />}
                                                    loading={this.state.loadingCcnt}
                                                    title={intl.formatMessage({ defaultMessage: '{count, plural, one {Ask to sign the report} other {Ask to sign {count} reports}}' }, { count: signableUsers.length })}
                                                />
                                            </Popconfirm>
                                                : null}
                                            <CircleButton
                                                small
                                                icon={< FAIcon prefix='fad' name='download' />}
                                                onClick={() => this.getCcntReport(undefined, true)}
                                                loading={this.state.loadingCcnt}
                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Download {count} report} other {Download {count} reports}}' }, { count: this.state.selectedUsers.length })}
                                            />
                                        </>
                                    }
                                />
                            </>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}><div><FormattedMessage defaultMessage={"No data"} /></div></div>
                        }
                    </div>
                </Spin>
                {
                    (isMultipleCcntVisible && multipleCcnt) ?
                        <MultipleCcntReportPrintPage multipleCcnt={multipleCcnt} close={() => this.setState({ isMultipleCcntVisible: false, multipleCcnt: undefined })} year={this.state.startDate} />
                        : null
                }
                <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={ccnt !== undefined} close={() => this.setState({ ccnt: undefined })} year={endDate} />

                <SignatureReminderModal isSending={this.state.isSendingReminder} onSendReminders={(ids) => this.remindSignature(ids)} users={remindableUsers} onClose={() => this.setState({ reminderModalOpened: false })} opened={this.state.reminderModalOpened} />
            </>
        );
    };
}

const mapStateToProps = (state: ApplicationState) => ({
    users: selectActiveReportUsers(state),
    company: state.user.company,
    groups: state.teamManagement.groups,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(withFullName(BalanceHours)));

interface StatCardProps {
    icon: ReactNode;
    text: string;
    count: number;
    color: string;
    onClick: () => void;
}
const StatCard = (props: StatCardProps) => {
    return (
        <div onClick={props.onClick} style={{ cursor: 'pointer', borderRadius: '12px', padding: '10px', fontSize: '90%' }}>
            <div style={{ display: 'flex', alignItems: 'center', letterSpacing: '.5px', gap: '5px' }}>
                <div style={{ fontWeight: 'bold', color: props.color, display: 'flex', alignItems: 'center' }}>{props.icon}</div>
                <div style={{ fontWeight: 'bold', color: props.color }}>{props.count}</div>
                <div style={{ fontWeight: 'normal', color: props.color }}>{props.text}</div>
            </div>
        </div>
    );
};

interface CcntBalancesByUserId {
    effectives: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    balances: {
        hours: number;
        rests: number;
        vacations: number;
        holidays: number;
    };
    daysOff: {
        sickness: number;
        accident: number;
        military: number;
        others: number;
    };
    contractTypeMode: number;
}