import { Button, Modal } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { filterUnsortedArrayByEmptying } from "../../../../../utils/array_utils";
import { ISignatureStatus, IUserContractInfo } from "../../../../../utils/types/generalTypes";
import FAIcon from "../../../../common/FAIcon";
import '../../common/signatureReminderModal/signatureReminderModal.css';

interface Props {
    users: (IUserContractInfo & { signature: ISignatureStatus; })[];
    opened: boolean;
    onClose: () => void;
    onSendReminders: (reportIds: number[]) => void;
    isSending: boolean;
}

const SignatureReminderModal = (props: Props) => {
    const rangeStepsInDays = [7, 14, 30];
    const intl = useIntl();

    const ranges = useMemo(() => {
        let users = [...props.users];
        console.log("Balls =======");
        return ([...rangeStepsInDays].reverse()).reduce((r, nbDays) => {
            const tresholdDate = moment().subtract(nbDays, 'days').format("YYYY-MM-DD");
            let usersForThisRange: Props['users'];
            [usersForThisRange, users] = filterUnsortedArrayByEmptying(users, (u) => (u.signature.reminderSent && u.signature.reminderSent.slice(0, 10).localeCompare(tresholdDate) <= 0 ? true : false));
            console.log("Balls", nbDays, usersForThisRange.map(u => u.signature.reminderSent?.slice(0, 10)));
            r.push({ nbDays, users: usersForThisRange });
            return r;
        }, [] as { nbDays: number; users: Props['users']; }[]).reverse();
    }, [props.users]);

    return (
        <Modal
            open={props.opened}
            afterClose={props.onClose}
            footer={null}
            onCancel={props.onClose}
            title={<FormattedMessage defaultMessage={'Grouped notifications'} />}
            width={'800px'}
        >
            <div className="reminder-modal-body">
                <div className="cards-header"><FormattedMessage defaultMessage={"Last reminder sent more than"} description={"After this text, there is a list of days. Ie: 3 days, 4 days.... Don't include the ':' in the translated text"} /></div>
                <div className="reminder-ranges">
                    {ranges.map((range) => (
                        <div key={range.nbDays} className="reminder-range-card">
                            <div className="range-treshold"><FormattedMessage defaultMessage={'{count, plural, one {a day ago} other {{count} days ago}}'} values={{ count: range.nbDays }} /></div>
                            <div className="user-icon">
                                <FAIcon prefix="fad" name="user" size="4x" />
                                <div className="user-count">{range.users.length}</div>
                            </div>
                            <Button disabled={props.isSending || range.users.length <= 0} type="default" onClick={() => props.onSendReminders(range.users.map(u => u.signature.reportId!))}>{intl.formatMessage({ defaultMessage: 'Send reminders' })}</Button>
                        </div>
                    ))}
                </div>

                <Button disabled={props.isSending || props.users.length <= 0} type="primary" onClick={() => props.onSendReminders(props.users.map(u => u.signature.reportId!))}>{intl.formatMessage({ defaultMessage: 'Send reminders to all' })}</Button>
            </div>
        </Modal>
    );
};

export default SignatureReminderModal;